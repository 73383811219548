import React from 'react'
import { PerthNowLogoHorizontal } from '../../logos/PerthNowLogo/PerthNowLogo'
import { colors } from '../../__styling/settings/colors'
import {
    StyledFlagContent,
    StyledIconContainer,
    StyledLogo,
    StyledSocialLinks,
    StyledStmSocialContainer,
    StyledStmSocialFlag,
    StyledTitle,
} from './PnlnSocialBanner.styled'

export interface PnlnSocialBannerProps {
    compact?: boolean
}

const socialLinks = {
    facebook: 'https://www.facebook.com/PerthNowLocalNews',
    twitter: 'https://x.com/PerthNowLocal',
    instagram: 'https://www.instagram.com/perthnowlocalnews/',
}

export const PnlnSocialBanner: React.FC<PnlnSocialBannerProps> = ({
    compact,
}) => (
    <StyledStmSocialContainer>
        <StyledLogo>
            <PerthNowLogoHorizontal
                width={100}
                height={48}
                textColor={colors.white}
            />
            <StyledTitle>Local News</StyledTitle>
        </StyledLogo>

        <StyledStmSocialFlag>
            <StyledFlagContent>
                <StyledIconContainer>
                    <StyledSocialLinks
                        compact={compact}
                        {...socialLinks}
                    ></StyledSocialLinks>
                </StyledIconContainer>
            </StyledFlagContent>
        </StyledStmSocialFlag>
    </StyledStmSocialContainer>
)
PnlnSocialBanner.displayName = 'SocialBanner'

export default PnlnSocialBanner
